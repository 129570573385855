//////////
// CICKS
//////////
const setCookie = (name, value, days = 7, path = '/') => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie =
    name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path;
};

const getCookie = name => {
  return document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=');
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, '');
};

const deleteCookie = (name, path) => {
  setCookie(name, '', -1, path);
};
(function($, APP) {
  APP.Plugins.Clicks = {
    init: function() {
      $(document)
        .on('click', '[href="#"]', function(e) {
          e.preventDefault();
        })
        // accardeon
        .on('click', '.js-accardeon .accardeon__toggler', function(e) {
          var $title = $(this);
          var $element = $title.parent();
          var $content = $element.find('.accardeon__content');

          // clear previous active element(s)
          // var $siblings = $element.siblings();
          // if ($siblings.length > 0) {
          //   $siblings.each(function(i, element) {
          //     var $element = $(element);
          //     var $content = $element.find('.accardeon__content');

          //     if ($element.is('.is-active')) {
          //       $element.removeClass('is-active');
          //       $content.slideUp();
          //     }
          //   });
          // }

          // target current element
          if ($element.is('.is-active')) {
            $element.removeClass('is-active');
            $content.slideUp();
          } else {
            $element.addClass('is-active');
            $content.slideDown();
          }
        })
        .on('click', '.js-regions-default', function() {
          setCookie('location', 1);
        })
        // tarif select
        .on('click', '.js-select-tarif', function(e) {
          // select by id in selectric
          var id = $(this).data('id');
          var $select = $('.form select');

          $select.val(id).selectric('refresh');

          // scroll to section
          var topTarget = $('.form').offset().top;

          $('body, html').animate({ scrollTop: topTarget }, 1000);
          return false;
        })
        .on('click', '.js-regions a', function() {
          var $link = $(this);
          var text = $link.text();

          // paste text to header location
          $('.header__region-text a').text(text);
          $('#REGION .popup__title span').text(text);
          $('.form #id_region').attr('value', $link.data('id'));
          $.magnificPopup.close();
          APP.Components.Header.hideRegionModal();

          setCookie('location', $link.data('id'));
          window.location.reload();
        });
    },
  };
})(jQuery, window.APP);
